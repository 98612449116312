import { createRouter, createWebHistory} from 'vue-router';
import scoreQuery from '../view/scoreQuery/scoreQuery'
import scoreDetail from '../view/scoreQuery/scoreDetail'
import imageView from '../view/imageView/imageView'
import shopIndex from '../view/shop/index'

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            name: 'scoreQuery',
            path: '/scoreQuery.html',
            component: scoreQuery
        },
        {
            name: 'scoreDetail',
            path: '/scoreDetail.html',
            component: scoreDetail
        },
        {
            name: 'imageView',
            path: '/imageView.html',
            component: imageView
        },
        {
            name: 'shop',
            path: '/shop.html',
            component: shopIndex
        }
    ]
})

export default router;