<template>
    <div class="scoreDiv">
        <span class="totalScore">{{ matchScore ? parseTime(matchScore.ganshotScore) : '' }}</span>
    </div>
    <div class="activityNameDiv">
        <p>{{ matchScore ? matchScore.matchName : '' }}</p>
        <p>马拉松</p>
    </div>

    <div>
        <van-grid :column-num="3">
            <van-grid-item>
                <span>{{ matchScore ? matchScore.realName : '' }}</span>
                <span class="itemTitle">姓名</span>
            </van-grid-item>
            <van-grid-item>
                <span>{{ matchScore && matchScore.gender == 1 ? '男' : '女'  }}</span>
                <span class="itemTitle">性别</span>
            </van-grid-item>
            <van-grid-item>
                <span>{{ matchScore ? matchScore.matchNumber : '' }}</span>
                <span class="itemTitle">参赛号</span>
            </van-grid-item>
            <van-grid-item>
                <span>{{ matchScore ? parseTime(matchScore.ganshotScore) : '' }}</span>
                <span class="itemTitle">枪声成绩</span>
            </van-grid-item>
            <van-grid-item>
                <span>{{ matchScore ? matchScore.ganshotRank : ''}}</span>
                <span class="itemTitle">枪声成绩排名(全部)</span>
            </van-grid-item>
            <van-grid-item>
                <span>{{ matchScore ? matchScore.ganshotGenderRank : '' }}</span>
                <span class="itemTitle">枪声成绩排名(性别)</span>
            </van-grid-item>
            <van-grid-item>
                <span>{{ matchScore ? parseTime(matchScore.realScore) : '' }}</span>
                <span class="itemTitle">净成绩</span>
            </van-grid-item>
            <van-grid-item>
                <span>{{ matchScore ? matchScore.realRank : '' }}</span>
                <span class="itemTitle">净成绩排名(全部)</span>
            </van-grid-item>
            <van-grid-item>
                <span>{{ matchScore ? matchScore.realGenderRank : '' }}</span>
                <span class="itemTitle">净成绩排名(性别)</span>
            </van-grid-item>
            <van-grid-item>
                <span>{{ matchScore ? (matchScore.ageRange - 4) + '~' + matchScore.ageRange : '' }}</span>
                <span class="itemTitle">年龄段</span>
            </van-grid-item>
            <van-grid-item>
                <span>{{ matchScore ? matchScore.ageRank : '' }}</span>
                <span class="itemTitle">年龄段排名</span>
            </van-grid-item>
            <van-grid-item>
                <span>{{ matchScore ? parseTime(matchScore.averageSpeed) + '/km' : ''}}</span>
                <span class="itemTitle">平均配速</span>
            </van-grid-item>
        </van-grid>
    </div>
    <div>
        <div class="scoreDetail">
            <span>分段计时</span>
        </div>
        <!--title-->
        <van-row justify="center" class="tableCellTitle">
            <van-col span="8">里程点</van-col>
            <van-col span="8">分段用时</van-col>
            <van-col span="8">分段配速</van-col>
        </van-row>
        <van-list
            v-model:loading="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
            >
            <div v-for="item in (matchScore ? matchScore.details : [])" :key="item" :title="item">
                <van-row justify="center" class="tableRow">
                    <van-col span="8">{{ item.point }}</van-col>
                    <van-col span="8">{{ parseTime(item.rangeTime) }}</van-col>
                    <van-col span="8">{{ parseTime(item.averageSpeed) }}</van-col>
                </van-row>
            </div>
        </van-list>
    </div>
</template>

<script>
import {getByNo} from '@/api/scoreApi'
export default{
    name: 'scoreDetail',
    data() {
        return {
            matchScore: {},
            activityId: '',
            idCard: '',
            token: '',
            list: [],
            loading: false,
            finished: false
        }
    },
    created() {
        this.init()
    },
    methods: {
        init() {
            this.finished = true;
            this.token = this.$route.query.token
            this.activityId = this.$route.query.activityId
            this.idCard = this.$route.query.idCard
            const queryParam = {
                'activityId': this.activityId,
                'no': this.idCard
            }
            getByNo(queryParam).then(res => {
                if (res.code === 0) {
                    this.matchScore = res.data
                }
            })

        },
        onLoad() {
            console.log('加载事件触发')
        },
        parseTime(time) {
            let hour = parseInt(time / 60 / 60  % 24)
            hour = hour < 10 ? '0' + hour : hour
            let minute = parseInt(time / 60 % 60)
            minute = minute < 10 ? '0' + minute : minute
            let second = parseInt(time % 60)
            second = second < 10 ? '0' + second : second
            return hour + ':' + minute + ':' + second
        }
    }
}
</script>

<style scoped>
    .scoreDiv {
        text-align: center;
        margin-top: 50px;
    }
    .totalScore {
        font-size: 72px;
        color: #FFA500;
    }
    .activityNameDiv {
        text-align: center;
        margin-top: 30px;
    }
    .itemTitle {
        font-size: 13px;
    }
    .scoreDetail {
        text-align: center;
        margin-top: 30px;
        font-size: 24px;
        font-weight: bold;
        color: #FFA500;
    }
    .tableCellTitle {
        text-align: center;
        font-weight: bold;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .tableRow {
        text-align: center;
        line-height: 32px;
        border: 1px solid #00a7d0;
    }
</style>