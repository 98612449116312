<template>
    <h4>成绩查询</h4>
    <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item>1</van-swipe-item>
        <van-swipe-item>2</van-swipe-item>
        <van-swipe-item>3</van-swipe-item>
        <van-swipe-item>4</van-swipe-item>
    </van-swipe>
    <van-form @submit="submit()" ref="formData">
        <van-cell-group insert>
            <van-field v-model="idCard" placeholder="请输入证件号码" autofocus="autofocus"/>
        </van-cell-group>
        <div style="margin: 16px;">
            <van-button round block type="primary" native-type="submit">
                查询
            </van-button>
        </div>
    </van-form>
</template>

<script>

export default {
    name: 'scoreQuery',
    data() {
        return {
            idCard: '',
            activityId: '',
            token: '',
            autofocus: true
        }
    },
    created() {
        this.init()
    },
    methods: {
        init() {
            this.activityId = this.$route.query.activityId
            this.token = this.$route.query.token
            if (!this.token) {
                alert('token不能为空')
            }
        },
        // 表单提交
        submit() {
            this.$router.push({name: 'scoreDetail', query: {activityId: this.activityId, idCard: this.idCard, token: this.token}})
        }
    }
}
</script>

<style scoped>
  .my-swipe .van-swipe-item {
    color: #fff;
    font-size: 20px;
    line-height: 150px;
    text-align: center;
    background-color: #39a9ed;
  }
</style>