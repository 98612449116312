<template>
    <div class="title">
        <p>图片浏览</p>
    </div>
    <div>
        <van-search
            v-model="no"
            show-action
            placeholder="请输入参赛号码"
            @search="onSearch">
            <template #action>
                <div @click="onClickButton">搜索</div>
            </template>
        </van-search>
    </div>
    <div>
        <van-grid :column-num="2" :gutter="10" >
            <van-grid-item v-for="item in images" :key="item.id" >
                <van-image :src="item.imageUrl" @click="onGridClick(item.imageUrl)" />
            </van-grid-item>
        </van-grid>
    </div>
</template>

<script>
import { list } from '@/api/imageApi'
import { showImagePreview } from 'vant'
export default {
    name: 'imageView',
    data() {
        return {
            no: '',
            images: '',
            image: '',
            activityId: '',
            show: false,
            token: ''
        }
    },
    created() {
        this.init()
    },
    methods: {
        init() {
            this.token = this.$route.query.token
            this.activityId = this.$route.query.activityId
            if (!this.token) {
                alert('token不能为空')
            }
            this.onSearch()
        },
        onSearch() {
            list({'no': this.no, 'token': this.token, 'activityId': this.activityId}).then(res => {
                if (res.code === 0) {
                    this.images = res.data
                }
            })
        },
        onClickButton() {
        },
        onChange() {

        },
        onGridClick(res) {
            showImagePreview([res]);
        }
    }
}
</script>

<style scoped>
    .title {
        text-align: center;
    }
</style>